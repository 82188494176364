import {Injectable} from '@angular/core';
import {IntegrationData} from "../models/integration-data";
import {EMPTY, fromEvent, map, Observable, of, switchMap, tap} from "rxjs";
import {StoreService} from "../../shared/services/store.service";
import {IntegrationInputData} from "../models/Integration-input-data";
import {User} from "../models/user";
import {Utils} from "../../shared/utils/generics.utils";
import {Vehicle} from "../models/vehicle";
import {PostcodeService} from "../../features/shared/postcode/postcode.service";
import {Contact} from "../models/contact";

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {

    constructor(private store: StoreService,
                private postcodeService: PostcodeService) {
        fromEvent(window, 'initData')
            .subscribe(e => this.setData((e as CustomEvent).detail))
    }

    // TOFIX: Set data coming from initData is not working anymore?
    setData(data: IntegrationInputData): Observable<boolean> {
        return of(true).pipe(tap((_) => {
            const vehicle: Vehicle = this.store.getVehicle();

            if (data.license) {
                this.store.clear();
                vehicle.license = data.license;

                if (data.mileage) {
                    vehicle.mileage = data.mileage;
                }
                this.store.setVehicle(vehicle);
            }

            if (data.phone || data.email) {
                const contact = new Contact();
                if (data.phone) {
                    const decodedPhone = decodeURI(data.phone);
                    if (Utils.isValidPhoneNumber(decodedPhone)) {
                        contact.phone = Utils.convertToE164(decodedPhone);
                    }
                }

                if (data.email) {
                    const decodedEmail = decodeURI(data.email);
                    if (Utils.isValidEmail(decodedEmail)) {
                        contact.email = decodedEmail;
                    }
                }
                this.store.setContact(contact);
            }


            if (data.externalId) {
                const decodedId = decodeURI(data.externalId);
                this.store.setExternalId(decodedId);
            }

            if (data.beta != null) {
                this.store.setBeta(data.beta);
            }
        }), switchMap((_) => {
            if (data.postcode) {
                return this.postcodeService.getPostcode(data.postcode).pipe(tap((values) => {
                    if (values.length >= 1) {
                        this.store.setPostcode(values[0]);
                    }
                }), map(() => _));
            }
            return of(_);
        }));
    }

    isStarted(data: IntegrationData) {
        // WebappInterface can be undefined if not integrated in Android WebView.
        if (typeof WebappInterface !== 'undefined') {
            WebappInterface.onStarted(JSON.stringify(data));
        }
        (window as any).webkit?.messageHandlers?.onStarted?.postMessage(data);
        parent.postMessage({type: 'onStarted', data}, '*');
        (window as any).ReactNativeWebView?.postMessage(JSON.stringify({type: 'onStarted', data}))
    }

    isFinished(data: IntegrationData) {
        // WebappInterface can be undefined if not integrated in Android WebView.
        if (typeof WebappInterface !== 'undefined') {
            WebappInterface.onFinished(JSON.stringify(data));
        }
        (window as any).webkit?.messageHandlers?.onFinished?.postMessage(data);
        parent.postMessage({type: 'onFinished', data}, '*');
        (window as any).ReactNativeWebView?.postMessage(JSON.stringify({type: 'onFinished', data}))

    }

    onError(data: IntegrationData) {
        if (typeof WebappInterface !== 'undefined') {
            WebappInterface.onError(JSON.stringify(data));
        }
        (window as any).webkit?.messageHandlers?.onError?.postMessage(data);
        parent.postMessage({type: 'onError', data}, '*');
        (window as any).ReactNativeWebView?.postMessage(JSON.stringify({type: 'onError', data}))
    }

}


