import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {EventTrackerService} from "../../services/event-tracker.service";

@Component({
  selector: 'app-dialog-cancel',
  templateUrl: './dialog-cancel.component.html',
  styleUrls: ['./dialog-cancel.component.scss']
})
export class DialogCancelComponent implements OnInit, OnDestroy {

  @Output() onClose = new EventEmitter<boolean>();
  constructor(private tracker: EventTrackerService) {
  }

  ngOnInit(): void {
    this.tracker.trackEvent('Dialog', 'Cancel', 'show');
  }
  ngOnDestroy(): void {
    this.tracker.trackEvent('Dialog', 'Cancel', 'hide');
  }

  onCloseDialog(_continue: boolean) {
    this.onClose.next(_continue);
  }

}
