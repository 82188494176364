<!-- Cancel dialog -->
<app-dialog data-test="cancel-dialog">
  <div class="flex flex-col py-8">
    <p class="mb-4 text-center">{{'photoshoot.cancel-description' | translate}}</p>
    <div class="flex flex-col gap-y-2">
      <button cdsButton appRipple data-test="abort-button" color="accent" (debounceClick)="onCloseDialog(true)">{{'photoshoot.restart' | translate}}</button>
      <button cdsButton appRipple data-test="continue-button" color="primary" (debounceClick)="onCloseDialog(false)">{{'photoshoot.continue' | translate}}</button>
    </div>
  </div>
</app-dialog>
