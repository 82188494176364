import {Component, inject, OnInit} from '@angular/core';
import {DialogOngoingAnalysisService} from "../../services/dialog-ongoing-analysis.service";
import {StoreService} from "../../services/store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Context} from "../../../core/models/context";
import {Utils} from "../../utils/generics.utils";

@Component({
  selector: 'app-dialog-ongoing-analysis',
  templateUrl: './dialog-ongoing-analysis.component.html',
  styleUrls: ['./dialog-ongoing-analysis.component.scss']
})
export class DialogOngoingAnalysisComponent implements OnInit {

  private dialogService = inject(DialogOngoingAnalysisService);
  private router = inject(Router);
  private store = inject(StoreService);

  shown$ = this.dialogService.shown$;

  ngOnInit(): void {
  }

  onClose() {
    this.dialogService.hide();
  }

  cancel() {
    this.store.clear();
    let route = '/';
    const fleet = this.store.getFleet();
    const company = Utils.extractSubcompany();
    const context = this.store.getContext();
    if (fleet) {
      if (context === Context.DIAGNOSIS) {
        if (company) {
          route = `/diagnosis/${fleet.key}/company/${company}/license`
        } else {
          route = `/diagnosis/${fleet.key}/license`
        }
      } else if (context === Context.SCANNER) {
        if (company) {
          route = `/scanner/${fleet.key}/company/${company}/license`
        } else {
          route = `/scanner/${fleet.key}/license`
        }
      } else if (context === Context.PRO) {
        route = `/pro/${fleet.key}/home`
      }
    }
    this.router.navigate([route], {queryParams: {nocheck: true, beta: this.store.getBeta()}}).then();
    this.dialogService.hide();
  }

}
